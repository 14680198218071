// eslint-disable-next-line
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

function Header() {
  const handleScheduleClick = () => {
    if (window.Calendly) {
      window.Calendly.initPopupWidget({
        url: "https://calendly.com/verseagency/30min",
      });
    }
  };

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://assets.calendly.com/assets/external/widget.js";
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const [showNav, setShowNav] = useState(false);
  const toggleNavbar = () => {
    setShowNav(!showNav);
  };
  const hideMenu = () => {
    setShowNav(false);
  };

  return (
    <header className="header-navbar">
      <link
        href="https://assets.calendly.com/assets/external/widget.css"
        rel="stylesheet"
      />

      <nav>
        <div className="nav-bar">
          <div className="nav-wrapper container">
            <div className="logo">
              <Link to="/">
                <img
                  src={require("..//assets/images/whitelogo-nobg.png")}
                  alt="Website logo"
                />
              </Link>
            </div>

            <div className="list">
              <ul className={`nav-list ${showNav ? "show" : "hide"}`}>
                <li onClick={hideMenu}>
                  <Link to="/">Home</Link>
                </li>
                <li onClick={hideMenu}>
                  <a href={"#about"}>About</a>
                </li>
                <li onClick={hideMenu}>
                  <Link to="/coming_soon">Projects</Link>
                </li>
                <li onClick={hideMenu}>
                  <a href={"#services"}>Services</a>
                </li>
              </ul>
            </div>

            <div className="call-to-action">
              <button
                href=""
                onClick={handleScheduleClick}
                className="ctaBtn nav-btn"
              >
                Book A Call
              </button>
              <div className="mobile_menu" onClick={toggleNavbar}>
                <img
                  src={
                    showNav
                      ? require("..//assets/images/close_icon.png")
                      : require("..//assets/images/ham_menu.png")
                  }
                  alt="Mobile Menu Icon"
                />
              </div>
            </div>
          </div>
        </div>
      </nav>
    </header>
  );
}
export default Header;
