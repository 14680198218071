import React from "react";
import { Routes, Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "bootstrap/dist/css/bootstrap.min.css.map";
import "../assets/styles.css";
import Home from "./Home";
import Footer from "../components/Footer";
import ComingSoon from "./Coming_soon";

function App() {
  return (
    <div>
      <Routes>
        <Route path="/" element={<Home />} />      
        <Route path="/coming_soon" element={<ComingSoon />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
