import React from "react";

function Cards(props) {
  return (
    <div>
      <div className="card-wrapper">
        <div className="card-top"></div>
        <div className="card-body">
          <div className="icon-wrapper">
            <img
              src={require(`..//assets/images/${props.img}`)}
              alt="icon"
            />
          </div>
          <div className="card-content">
            <h5 className="card-title">{`${props.title}`}</h5>
            <p className="card-description">
            {`${props.description}`}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Cards;
