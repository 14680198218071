import React from "react";

function Testimonials(props) {
  return (
    <div>
      <div className="testimonialCard-wrapper">
        <div className="testimonialCard-body">
          <div className="testimonialCard-content">
            <p className="testimonialCard-paragraph">"{`${props.description}`}"</p>
          </div>
        </div>
        <div className="testimonialCard-footer">
          <div className="testimonialCard-info">
            <p className="testimonialCard-name">{`${props.name}`}</p>
            <p className="testimonialCard-jobtitle">{`${props.title}`}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Testimonials;
