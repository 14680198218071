import React from "react";
import AOS from 'aos';
import { Link } from "react-router-dom";

import 'aos/dist/aos.css'; 
AOS.init();
function Footer() {
  const scrollToTop = () => {
    // Scroll to the top of the page
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };
  return (
    <div>
      <footer className="footer" data-aos="fade-up">
        <div className="container">
          <div className="row footer-top">
            <div className="col-md-3 footer-column">
              <img
                src={require("..//assets/images/whitelogo-nobg.png")}
                alt="Logo"
                className="footer-logo"
              />
              <p className="footer-company-description">
                We craft seamless, engaging online experiences that drive
                results.
              </p>
            </div>

            <div className="col-md-3 footer-column">
              <h5 className="footer-column-title">Useful Links</h5>
              <ul className="footer-column-list">
                <li>
                  <a href={'/#services'}>Services</a>
                </li>
                <li>
                  <a href={'/#testimonials'}>Tesimonials</a>
                </li>
                <li>
                 <Link to="/coming_soon" onClick={scrollToTop}>Projects</Link>
                </li>
              </ul>
            </div>

            <div className="col-md-3 footer-column">
              <h5 className="footer-column-title">Contact Us</h5>
              <ul  className="footer-column-list">
                <li>
                  <a href="mailto:rachel@versedigital.media">
                   Rachel@versedigital.media
                  </a>
                </li>
                <li>
                  <a href="tel:96181644235">+961-81 644 235</a>
                </li>
              </ul>
            </div>

            <div className="col-md-3 footer-column social-links">
              <a href="https://www.facebook.com/profile.php?id=100090192540958&mibextid=ZbWKwL" target="_blank" rel="noreferrer">
                <img
                  src={require("..//assets/images/facebook-logo.png")}
                  alt="Facebook"
                />
              </a>
              <a href="https://instagram.com/verse_agency" target="_blank" rel="noreferrer">
                <img
                  src={require("..//assets/images/insta-logo.png")}
                  alt="Instagram"
                />
              </a>
              <a href="https://www.linkedin.com/in/verse-agency-205645247" target="_blank" rel="noreferrer">
                <img
                  src={require("..//assets/images/linkedin-logo.png")}
                  alt="LinkedIn"
                />
              </a>
            </div>
          </div>
          <div className="line-seperator"></div>
          <div className="footer-bottom">
            <p className="footer-copyrights">
              Copyright © 2023 Verse Media.All Rights Reserved |{" "}
              <a href="https://www.instagram.com/alaaezdine.ai" className="madeBy">
                Made By Alaa Abi Ezdine
              </a>
            </p>
          </div>
        </div>
      </footer>
    </div>
  );
}
export default Footer;

