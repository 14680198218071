import React, { useEffect, useState } from "react";
// import Cards from "../components/Cards";
// import Testimonials from "../components/Testimonials";
import Header from "../components/Header";
import AOS from "aos";
import SwiperCarousel from "../components/swiper";
import TestimonialSwiper from "../components/testimonialSwiper";
import "aos/dist/aos.css";
AOS.init();

function Home() {
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const [isVisible, setIsVisible] = useState(false);

  const checkScrollTop = () => {
    if (window.pageYOffset > 100) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  window.addEventListener("scroll", checkScrollTop);

  const handleScheduleClick = () => {
    if (window.Calendly) {
      window.Calendly.initPopupWidget({
        url: "https://calendly.com/verseagency/30min",
      });
    }
  };

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://assets.calendly.com/assets/external/widget.js";
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div>
      {/* Hero-Section */}
      <link
        href="https://assets.calendly.com/assets/external/widget.css"
        rel="stylesheet"
      />

      <div className="hero-section">
        <Header></Header>
        <div className="container">
          <div className="row">
            <div
              className="col-lg-6 col-md-12 col-sm-12 d-flex align-items-center"
              data-aos="fade-up"
            >
              <div className="hero-content" data-aos="fade-right">
                <h1 className="hero-title">
                  Your Gateway to Digital <span>Success</span>
                </h1>
                <p className="hero-description">
                  Welcome to our digital playground, where creativity meets
                  technology. We craft seamless, engaging online experiences
                  that drive results.
                </p>
                <button
                  className="btn commonBtn hero-btn"
                  onClick={handleScheduleClick}
                >
                  Contact Us
                </button>
              </div>
            </div>
            <div className="col-md-6" data-aos="fade-right">
              <div className="image-container">
                <div className="hero-shape"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div >
        {isVisible && (
          <div onClick={scrollToTop} className="scrollTop scroll-to-top-btn">
           <img width="23" height="23" src="https://img.icons8.com/ios/23/FFFFFF/long-arrow-up--v1.png" alt="long-arrow-up--v1"/>
          </div>
        )}
      </div>
      {/* about-section */}
      <div className="about-section" id="about">
        <div className="container">
          <div className="row about-wrapper">
            <div className="col-md-6">
              <div className="about-image-container" data-aos="fade-up">
                <img
                  src={require("..//assets/images/about-image.png")}
                  className="about-image"
                  alt="Digital Success"
                />
              </div>
            </div>
            <div className="col-lg-6 col-md-12 d-flex align-items-center">
              <div className="about-content" data-aos="fade-up">
                <p className="about-subtitle">Welcome To Verse</p>
                <h2 className="about-title">Your Digital Partner</h2>
                <p className="about-description">
                  Our extensive knowledge of the digital marketing sector and
                  our thoroughness in finding the best solution for your
                  business is at the heart of our agency.
                </p>
                <ul className="about-list">
                  <li className="about-list-point">
                    <img
                      src={require("..//assets/images/bullet-point.png")}
                      alt="bullet Point"
                    />
                    <p>Expertise in digital marketing solutions.</p>
                  </li>
                  <li className="about-list-point">
                    <img
                      src={require("..//assets/images/bullet-point.png")}
                      alt="bullet Point"
                    />
                    <p>Tailored strategies for unique business needs.</p>
                  </li>
                  <li className="about-list-point">
                    <img
                      src={require("..//assets/images/bullet-point.png")}
                      alt="bullet Point"
                    />
                    <p>Continuous optimization for optimal results.</p>
                  </li>
                </ul>
                <button
                  className="btn commonBtn about-btn"
                  onClick={handleScheduleClick}
                >
                  Book A Call
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Intro Video */}
      <div className="intro-section" data-aos="fade-up">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="intro-content">
                <p className="intro-subtitle">Let Us Help You</p>
                <h3 className="intro-title">We’re Experts In The Business</h3>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="video-thumbnail" data-aos="fade-up">
        <div className="img-wrapper">
          <img
            src={require("..//assets/images/watchvideo.jpg")}
            alt="video-thumbnail"
          />
        </div>

        <div class="waves-block">
          <div class="waves wave-1"></div>
          <div class="waves wave-2"></div>
          <div class="waves wave-3"></div>
        </div>
        <div className="video-icon">
          <img
            onClick={handleOpen}
            src={require("..//assets/images/play.png")}
            alt="play icon"
          />
        </div>
      </div>

      {/* Services Section */}
      <div className="services-section" data-aos="fade-up" id="services">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="services-content">
                <p className="services-subtitle">What We Are Offering</p>
                <h3 className="services-title">
                  Services built specifically for your business
                </h3>
              </div>
              <div
                className="row services-body"
                data-aos="fade-up"
                data-aos-duration="110"
              >
                <SwiperCarousel />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Why Choose Us */}
      <div className="chooseus-section" data-aos="fade-up">
        <div className="container">
          <div className="row">
            <div className="col-md-6 d-flex align-items-center">
              <div className="chooseus-content">
                <p className="chooseus-subtitle">Why Choose Us</p>
                <h4 className="chooseus-title">What Make Us Different ?</h4>
                <p className="chooseus-description">
                  We’re a values – driven company that provides affordable,
                  marketing Service.
                  <br></br>
                  <br></br>
                  At the core of our agency is our profound expertise in the
                  digital marketing sector and our unwavering commitment to
                  discovering the ideal solutions for your business.
                </p>
                <button
                  className="btn commonBtn chooseus-btn"
                  onClick={handleScheduleClick}
                >
                  Contact Us
                </button>
              </div>
            </div>
            <div className="col-md-6">
              <div className="chooseus-image-container">
                <img
                  src={require("..//assets/images/whychooseUs.png")}
                  className="chooseus-image"
                  alt="Digital Success"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* testimonials Section */}
      <div
        className="testimonials-section"
        data-aos="fade-up"
        id="testimonials"
      >
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="testimonials-content">
                <p className="testimonials-subtitle">Testimonials</p>
                <h3 className="testimonials-title">
                  What Clients Say About Us?
                </h3>
              </div>
              <div
                className="row testimonials-body"
                data-aos="fade-up"
                data-aos-duration="110"
              >
                <TestimonialSwiper />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* CTA Section */}
      <div className="cta-section" data-aos="fade-up">
        <div className="container">
          <div className="row cta-wrapper">
            <div className="col-lg-6">
              <div className="cta-content">
                <h5 className="cta-title">Book Your Free Call today</h5>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="cta-button" onClick={handleScheduleClick}>
                <button className="btn commonBtn cta-btn">Book A Call</button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* popup */}
      {open && (
        <div className="video-popup-wrapper">
          <div id="video-popup" className="overlay">
            <div className="popup">
              <button className="close" onClick={handleClose}>
                &times;
              </button>
              <div id="dialog" className="window">
                <iframe
                  width="100%"
                  max-width="800"
                  height="500"
                  src="https://www.youtube.com/embed/yrGr2x029no?autoplay=1"
                  frameborder="0"
                  title="intro video"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* popup */}
    </div>
  );
}

export default Home;
