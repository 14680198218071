import React, { useEffect } from "react";

function ComingSoon() {
  const handleScheduleClick = () => {
    if (window.Calendly) {
      window.Calendly.initPopupWidget({
        url: "https://calendly.com/verseagency/30min",
      });
    }
  };

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://assets.calendly.com/assets/external/widget.js";
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);
  return (
    <div>
      <link
        href="https://assets.calendly.com/assets/external/widget.css"
        rel="stylesheet"
      />
      <div className="comingSoon_page">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="row">
                <div className="col-lg-12">
                  <div className="page_content">
                    <h3 className="comingSoon_title">
                      Coming <span>Soon!!</span>
                    </h3>
                    <p className="comingSoon_text">
                      Book A Call With Hour Team To Know More About Us.
                    </p>

                    <button
                      type="submit"
                      onClick={handleScheduleClick}
                      className="ctaBtn subscribeBtn"
                    >
                      Book A Call
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default ComingSoon;
