import { Navigation, Pagination, A11y } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import Cards from "./Cards";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

function SwiperCarousel() {
  const breakpoints = {
    1200: {
      slidesPerView: 3,
      spaceBetween: 10,
    },
    990: {
      slidesPerView: 2,
      spaceBetween: 10,
    },
    0: {
      slidesPerView: 1,
      spaceBetween: 10,
    },
  };
  return (
    <div className="all_projects" >
      <Swiper
        spaceBetween={10}
        //slidesPerView={3}
        breakpoints={breakpoints}
        modules={[Navigation, Pagination, A11y]}
        navigation
        onSlideChange={() => console.log("slide change")}
        onSwiper={(swiper) => console.log(swiper)}
      >
        <SwiperSlide>
          <Cards
            img="graphic-design.png"
            title="Graphic Design"
            description="Transforming ideas 
into visually captivating masterpieces"
          />
        </SwiperSlide>
        <SwiperSlide>
          <Cards
            img="web-development.png"
            title="Web Development"
            description="Crafting dynamic 
websites for seamless 
online experiences."
          />
        </SwiperSlide>
        <SwiperSlide>
          <Cards
            img="photography.png"
            title="Photography"
            description="Capturing your great moments 
with creativity and 
precision."
          />
        </SwiperSlide>
        <SwiperSlide>
          <Cards
            img="video-editing.png"
            title="Video Editing"
            description="
            Turning thoughts into stunning video masterpieces."
          />
        </SwiperSlide>
      </Swiper>
    </div>
  );
}
export default SwiperCarousel;
