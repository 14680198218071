import { Navigation, Pagination, A11y } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import Testimonials from "./Testimonials";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

function TestimonialSwiper() {
  const breakpoints = {
    1200: {
      slidesPerView: 2,
      spaceBetween: 10,
    },
    0: {
      slidesPerView: 1,
      spaceBetween: 10,
    },
  };
  return (
    <div className="all_projects all_projects_testimonial">
      <Swiper
        spaceBetween={10}
        //slidesPerView={3}
        breakpoints={breakpoints}
        modules={[Navigation, Pagination, A11y]}
        navigation
        onSlideChange={() => console.log("slide change")}
        onSwiper={(swiper) => console.log(swiper)}
      >
        <SwiperSlide>
          <Testimonials
            name="Mohammad Al Habida"
            title="That Ruya Constructions"
            description="Exceptional service! Their expertise and dedication exceeded my expectations. I highly recommend their professional approach and outstanding results."
          />
        </SwiperSlide>
        <SwiperSlide>
          <Testimonials
            name="Mohammad Awdi"
            title="Founder of Awdi-Group"
            description=" They exceeded my expectations, delivering top-notch results. Their team's expertise and dedication made a significant impact on our project."
          />
        </SwiperSlide>
        <SwiperSlide>
          <Testimonials
            name="Naila Fares"
            title="Havani Boutique"
            description=" Working with Verse media really changed the way our business operate.I was surprised by the results of our campaigns and sales we got from it."
          />
        </SwiperSlide>
      </Swiper>
    </div>
  );
}
export default TestimonialSwiper;
